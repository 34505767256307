/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarSide, faWalking } from '@fortawesome/free-solid-svg-icons';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import moment from 'moment';
import _ from "lodash";
import {
	getQueryVariable,
	isNowCompareToTargetTime,
	isIOCompareToTargetTime,
} from "services";

import {
	listCarryoutTask,
	triggerClearTask,
	triggerSendTask,
	nextPage,
	resetPage,
	soundCheck
} from "../actions";
import { REFRESH_FEQ } from "config";
import { STATE } from "constants/variable";
import { FooterComponent } from "./Footer";

// import alertSound from '../assets/audio/sweetsoundeffect.wav';

class ContentBase extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);

		this.state = {
			groupId: getQueryVariable("storeId"),
			filter: "",
			start: moment().startOf("day").valueOf(),
			end: moment().endOf("day").valueOf(),
			customer_arrived: false,
			pageSize: 100,
			time: moment()
		};

		this.audioRef = React.createRef();
		this.handleSendClick = this.handleSendClick.bind(this);
		this.handleClearClick = this.handleClearClick.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.onLoad();
		this.timerInterval = setInterval(() => { this.setState({ time: moment() }) }, 1000);
		this.refreshInterval = setInterval(() => {
			this.props.resetPage()
				.then(() => this.onLoad());
		}, REFRESH_FEQ);
	}

	componentWillUnmount() {
		this._isMounted = false;
		clearInterval(this.timerInterval);
		clearInterval(this.refreshInterval);
	}

	onLoad() {
		const { groupId } = this.state;

		if (!groupId) {
			this.props.history.push(ROUTES.NOTFOUND);
		}
		this.props.listCarryoutTask(this.state, this.props);
	}

	onTriggerLoad() {
		const { currentPage, totalPage } = this.props;
		if (currentPage < totalPage) {
			this.onLoad();
		}
	}

	handleScroll(e) {
		let element = e.target;
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			// do something at end of scroll
			this.props.nextPage()
				.then(() => this.onTriggerLoad());
		}
	}

	handleClearClick(carryoutInfo) {
		this.props
			.triggerClearTask(carryoutInfo.taskCode);
	}

	handleSendClick(carryoutInfo) {
		this.props.triggerSendTask(carryoutInfo.taskCode);
	}

	renderPanelBlock(carryoutInfo, i) {
		const { clientInfo, detailInfo, driveUpInfo, storeActionInfo } = carryoutInfo;

		if (storeActionInfo && storeActionInfo.finishClickDateTime) return <tr key={i}></tr>
		return (
			<tr key={i}>
				<td className={`has-text-centered ${this.orderIsLate(carryoutInfo) ? "has-text-danger" : null}`}>
					{
						detailInfo.methodType &&
							detailInfo.methodType === 'DRIVE_UP' ?
							<span className="icon" style={{ fontSize: "1.5rem" }}>
								<FontAwesomeIcon icon={faCarSide} />
							</span>
							:
							<span className="icon" style={{ fontSize: "1.5rem" }}>
								<FontAwesomeIcon icon={faWalking} />
							</span>
					}
				</td>
				<td>
					<p className={`subtitle is-7 ${this.orderIsLate(carryoutInfo) ? "has-text-danger" : null}`}>
						{clientInfo.customerName} ({detailInfo.orderId}) {clientInfo.customerMsisdn && (clientInfo.customerMsisdn)}
							<br />
						{
							driveUpInfo &&
							detailInfo.methodType &&
							detailInfo.methodType === 'DRIVE_UP' &&
							<>{driveUpInfo.vehicleMake}, {driveUpInfo.vehicleModel}, {driveUpInfo.vehicleColor}, {driveUpInfo.vehicleRegNumber} </>
						}
					</p>
				</td>

				<td>
					{
						!storeActionInfo &&
						<button className="button is-small is-pulled-right" onClick={() => this.handleSendClick(carryoutInfo)} title="Send to customer">SEND</button>
					}

					{
						storeActionInfo &&
						storeActionInfo.sendClickDateTime &&
						!storeActionInfo.finishClickDateTime &&
						<button className="button is-primary is-small is-pulled-right" onClick={() => this.handleClearClick(carryoutInfo)} title="Clear">FINISH</button>
					}
				</td>
				<td>
					<p className={`subtitle is-6 is-pulled-right ${this.orderIsLate(carryoutInfo) ? "has-text-danger" : ""}`}>
						{this.renderCountdownTimer(carryoutInfo)}
					</p>
				</td>
			</tr>
		);
	}

	renderCountdownTimer(carryoutInfo) {
		const { taskLogInfoList, storeActionInfo } = carryoutInfo;
		const orderDropped = _.find(taskLogInfoList, (o) => o.state === STATE.PLACE_ORDER);
		if (!orderDropped) return <></>;
		if (storeActionInfo && storeActionInfo.sendClickDateTime) return <></>;

		const orderDroppedTime = moment(orderDropped.serverDatetime.$date);
		const currentTime = moment();
		const duration = moment.utc(currentTime.diff(orderDroppedTime)).format("HH:mm:ss");

		// current time - (Order Drop to Store time ) the state “PC”
		return (
			<>
				{orderDropped && orderDroppedTime && duration}
			</>
		);
	}

	orderIsLate(carryoutInfo) {
		const { detailInfo, taskLogInfoList } = carryoutInfo;
		const { coSettingInfo } = detailInfo;

		const targetOrderTime = moment(detailInfo.targetDeliveryDateTime.$date);
		let inOvenToReadyBufferMin = 7;
		let orderDropToReadyBufferMin = 8;

		if (coSettingInfo) {
			inOvenToReadyBufferMin = coSettingInfo.inOvenToReadyBufferMin;
			orderDropToReadyBufferMin = coSettingInfo.orderDropToReadyBufferMin;
		}

		const orderInOven = _.find(taskLogInfoList, (o) => o.state === STATE.IN_OVEN);

		// if (carryoutInfo.state === STATE.COMPLETE) {
		// 	if (storeActionInfo && storeActionInfo.sendClickDateTime) {
		// 		return false;
		// 	} else {
		// 		return false;
		// 	}
		// } else {
			if (orderInOven) {
				if (isIOCompareToTargetTime(moment(orderInOven.serverDatetime.$date), targetOrderTime, inOvenToReadyBufferMin)) { //if IO + 7 > delivery time
					return true;
				} else {
					return false;
				}
			} else {
				if (isNowCompareToTargetTime(targetOrderTime, orderDropToReadyBufferMin)) { //if currentTime + 8 > delivery time
					return true;
				} else {
					return false;
				}
			}
		// }
	}

	renderTimer() {
		const { time, groupId } = this.state;
		const { lastDateTimeWebsocket } = this.props;
		return (
			<>
				<button className="button is-dark is-small is-clearfix is-pulled-left is-overlay" onClick={() => this.props.soundCheck()}>Sound Check</button>

				<h3 className="title is-6 has-text-centered m-t-md">
					Current time at {groupId} - {moment(time).format("DD/MM/YYYY HH:mm:ss")}
				</h3>
				{
					lastDateTimeWebsocket &&
					<p className={`subtitle has-text-centered m-t-md ${this.isWebsocketLate(lastDateTimeWebsocket) ? "has-text-danger has-text-weight-bold is-6" : "has-text-grey is-7"}`}>Last Updated: {lastDateTimeWebsocket.fromNow()}</p>
				}
			</>
		);
	}

	isWebsocketLate(lastDateTimeWebsocket) {
		if(moment().isSameOrAfter(lastDateTimeWebsocket.clone().add(3, 'm'), "m")){
			window.location.reload();
		}
		return moment().isSameOrAfter(lastDateTimeWebsocket.clone().add(2, 'm'), "m");
	}

	getOrderReadyCustomerIsHere(carryoutInfoList) {
		return (
			<>
				{carryoutInfoList.filter(
					carryoutInfo =>
						carryoutInfo.clientActionInfo &&
						carryoutInfo.clientActionInfo.isHereEventDateTime &&
						carryoutInfo.state === STATE.COMPLETE
				).map((carryoutInfo, i) => this.renderPanelBlock(carryoutInfo, i))
				}
			</>
		);
	};

	getOrderNotReadyCustomerIsHere(carryoutInfoList) {
		return (
			<>
				{carryoutInfoList.filter(
					carryoutInfo =>
						carryoutInfo.clientActionInfo &&
						carryoutInfo.clientActionInfo.isHereEventDateTime &&
						carryoutInfo.state === STATE.IN_OVEN
				).map((carryoutInfo, i) => this.renderPanelBlock(carryoutInfo, i))
				}
			</>
		);
	};

	getOrderReadyCustomerNotHere(carryoutInfoList) {

		return (
			<>
				{carryoutInfoList.filter(carryoutInfo =>
					!(carryoutInfo.clientActionInfo &&
						carryoutInfo.clientActionInfo.isHereEventDateTime) &&
					carryoutInfo.state === STATE.COMPLETE
				).map((carryoutInfo, i) => this.renderPanelBlock(carryoutInfo, i))
				}
			</>
		);
	};

	getOrderNotReadyCustomerNotHere(carryoutInfoList) {

		return (
			<>
				{carryoutInfoList.filter(carryoutInfo =>
					!(carryoutInfo.clientActionInfo &&
						carryoutInfo.clientActionInfo.isHereEventDateTime) &&
					carryoutInfo.state === STATE.IN_OVEN
				).map((carryoutInfo, i) => this.renderPanelBlock(carryoutInfo, i))
				}
			</>
		);
	};

	renderCustomerIsHere(carryoutInfoList) {
		return (
			<>
				<div className="panel is-primary">
					<h3 className="panel-heading has-text-centered is-5">
						Customer Here!
						<div className="subtitle is-7 has-text-white m-t-md"></div>
					</h3>
					<p className="subtitle is-6 has-text-centered m-t-md"><u>Order Ready</u></p>
					<div className="table-container" onScroll={this.handleScroll}>
						<table className="table is-fullwidth">
							<tbody>
								{this.getOrderReadyCustomerIsHere(carryoutInfoList)}
							</tbody>
						</table>
					</div>
					<hr />

					<p className="subtitle is-6 has-text-centered m-t-md"><u>Order Loaded</u></p>
					<hr />

					<div className="table-container" onScroll={this.handleScroll}>
						<table className="table is-fullwidth">
							<tbody>
								{this.getOrderNotReadyCustomerIsHere(carryoutInfoList)}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}


	renderCustomerIsNotHere(carryoutInfoList) {
		return (
			<>
				<div className="panel is-danger">
					<h3 className="panel-heading has-text-centered is-5">
						Customer Not Here
						<div className="subtitle is-7 has-text-white">( Issue EFPP if Order is RED )</div>
					</h3>
					<p className="subtitle is-6 has-text-centered m-t-md"><u>Order Ready</u></p>
					<div className="table-container" onScroll={this.handleScroll}>
						<table className="table is-fullwidth">
							<tbody>
								{carryoutInfoList.length > 0 && this.getOrderReadyCustomerNotHere(carryoutInfoList)}
							</tbody>
						</table>
					</div>
					<hr />
					<p className="subtitle is-6 has-text-centered m-t-md"><u>Order Loaded</u></p>
					<hr />

					<div className="table-container" onScroll={this.handleScroll}>
						<table className="table is-fullwidth">
							<tbody>
								{carryoutInfoList.length > 0 && this.getOrderNotReadyCustomerNotHere(carryoutInfoList)}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}

	render() {
		const { carryoutInfoList } = this.props;
		if (!carryoutInfoList) return null;
		return (
			<>
				<FooterComponent />
				<section className="hero">
					<div className="hero-body" style={{ paddingTop: "0.5rem" }}>
						<div className="container is-widescreen is-fullhd">
							{this.renderTimer()}
							<div className="columns">
								<div className="column">
									{this.renderCustomerIsHere(carryoutInfoList)}
								</div>
								<div className="column">
									{this.renderCustomerIsNotHere(carryoutInfoList)}
								</div>
							</div>
							{/* <audio id="audio_tag" src={alertSound} muted></audio> */}
						</div>
					</div>
				</section>
			</>
		);
	}
}

const mapStateToProps = ({ main }) => {
	const { carryoutInfoList, lastDateTimeWebsocket, currentPage, totalPage } = main;
	return { carryoutInfoList, lastDateTimeWebsocket, currentPage, totalPage };
};
const Content = compose(
	withRouter,
	connect(mapStateToProps, {
		listCarryoutTask,
		triggerClearTask,
		triggerSendTask,
		nextPage,
		resetPage,
		soundCheck
	})
)(ContentBase);

export default Content;
