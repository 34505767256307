import axios from 'axios';

import {
    LIST_CARRYOUT_TASK_SUCCESS,
    LIST_CARRYOUT_TASK_FAIL,
    CLEAR_CARRYOUT_TASK,
    CLEAR_CARRYOUT_TASK_SUCCESS,
    CLEAR_CARRYOUT_TASK_FAIL,
    SEND_CARRYOUT_TASK,
    SEND_CARRYOUT_TASK_SUCCESS,
    SEND_CARRYOUT_TASK_FAIL,
    GET_GROUP_INFO,
    GET_GROUP_INFO_FAIL,
    GET_GROUP_INFO_SUCCESS,
    PLAY_AUDIO,
    STOP_AUDIO,
    NEXT_PAGE,
    RESET_PAGE
} from './types';

import * as UtliService from "../services/utli-service";

import {
    showLoading,
    hideLoading
} from './LoadingActions';

export const soundCheck = () => {
    return async (dispatch) => {
        dispatch({type: PLAY_AUDIO, payload: 'alert'})
        // dispatch({type: STOP_AUDIO, payload: 'alert'})
        setTimeout(() => {
            dispatch({type: PLAY_AUDIO, payload: 'customerishere'})
            // dispatch({type: STOP_AUDIO, payload: 'customerishere'})
          }, 1500)
    };
}


export const playAudio = () => {
    return async (dispatch) => {
        dispatch({
            type: PLAY_AUDIO,
        });
    };
}

export const stopAudio = () => {
    return async (dispatch) => {
        dispatch({
            type: STOP_AUDIO,
        });
    };
}

export const nextPage = () => {
    return async (dispatch) => {
        dispatch({
            type: NEXT_PAGE,
        });
    }
}

export const resetPage = () => {
    return async (dispatch) => {
        dispatch({
            type: RESET_PAGE
        });
    }
};

// ---------------------------------------------------------------- 
// ------------------------- LIST CARRYOUT TASK ----------------
//-----------------------------------------------------------------

export const listCarryoutTask = ({
    groupId,
    filter,
    start,
    end,
    customer_arrived,
    pageSize
},{currentPage}) => {
    return async (dispatch, getState) => {
        dispatch(showLoading());
        // dispatch({
        //     type: LIST_CARRYOUT_TASK
        // });
        
        return axios({
            method: 'get',
            url: `/carryout/otc/${groupId}`,
            headers: {},
            params: {
                groupId,
                // filter,
                start,
                end,
                // customer_arrived,
                page: currentPage,
                pageSize
            },
        })
            .then(response => listCarryoutTaskSuccess(dispatch, getState, response))
            .catch(error => listCarryoutTaskFail(dispatch, error));
    };
}

const listCarryoutTaskSuccess = (dispatch, getState, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        let currentPage = getState().main.currentPage;
        let existingList = getState().main.carryoutInfoList;
        let newList = response.data.taskList;

        dispatch({
            type: LIST_CARRYOUT_TASK_SUCCESS,
            payload:  {
                taskList: currentPage === 0 ? newList : [...existingList,...newList],
                totalPage: response.data.totalPage
            },
        });
    } else {
        UtliService.alertError(response);
    }
};

const listCarryoutTaskFail = (dispatch, error) => {
    dispatch(hideLoading());
    dispatch({
        type: LIST_CARRYOUT_TASK_FAIL,
        payload: error
    });
};

// ---------------------------------------------------------------- 
// ------------------------- CLEAR TASK  --------------------
//-----------------------------------------------------------------

export const triggerClearTask = (taskCode) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: CLEAR_CARRYOUT_TASK
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/otc/clear/${taskCode}`,
            params: {}
        })
            .then(response => triggerClearTaskSuccess(dispatch, response))
            .catch(error => triggerClearTaskFail(dispatch, error));
    };
}

const triggerClearTaskSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: CLEAR_CARRYOUT_TASK_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const triggerClearTaskFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: CLEAR_CARRYOUT_TASK_FAIL,
        payload: error
    });
};


// ---------------------------------------------------------------- 
// ------------------------- SEND TASK  --------------------
//-----------------------------------------------------------------

export const triggerSendTask = (taskCode) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: SEND_CARRYOUT_TASK
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/otc/send/${taskCode}`,
            params: {}
        })
            .then(response => triggerSendTaskSuccess(dispatch, response))
            .catch(error => triggerSendTaskFail(dispatch, error));
    };
}

const triggerSendTaskSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: SEND_CARRYOUT_TASK_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const triggerSendTaskFail = (dispatch, error) => {
    dispatch(hideLoading());

    UtliService.alertError(error);
    dispatch({
        type: SEND_CARRYOUT_TASK_FAIL,
        payload: error
    });
};

// ---------------------------------------------------------------- 
// ------------------------- GET GROUP INFO ----------------
//-----------------------------------------------------------------

export const getGroupInfoByID = ({ groupId }) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: GET_GROUP_INFO
        });

        return axios({
            method: 'GET',
            url: `/group/${groupId}`,
            headers: {},
            params: { groupId },
        })
            .then(response => getGroupInfoByIDSuccess(dispatch, response))
            .catch(error => getGroupInfoByIDFail(dispatch, error));
    };
}

const getGroupInfoByIDSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: GET_GROUP_INFO_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const getGroupInfoByIDFail = (dispatch, error) => {
    dispatch(hideLoading());
    dispatch({
        type: GET_GROUP_INFO_FAIL,
        payload: error
    });
};