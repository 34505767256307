import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    playSpeech,
    stopSpeech,
} from '../actions'


class Text2Speech extends Component {
    componentDidMount() {
        this.onLoad();
    }

    componentWillUnmount() {

    }

    onLoad() {
        window.responsiveVoice.enableWindowClickHook();
        // window.responsiveVoice.setDefaultVoice('US English Female');
    }

    componentDidUpdate(prevProps) {
        if (this.props.speaking) {
            if (window.responsiveVoice.voiceSupport()) {
                window.responsiveVoice.speak(this.props.content, "US English Female", { onstart: null, onend: () => this.props.stopSpeech() });
            }
        }
    }

    render() {
        return (
            <div>
                {/* <button onClick={() => this.props.playSpeech('hello world')}>Speech</button> */}
            </div>
        );
    }
}

const mapStateToProps = ({ speech }) => {
    const { speaking, content } = speech;
    return {
        speaking,
        content,
    };
};

export default connect(mapStateToProps, {
    playSpeech,
    stopSpeech,
})(Text2Speech);