import { 
    PLAY_SPEECH, 
    STOP_SPEECH,
} from '../actions/types';

const INITIAL_STATE = {
  speaking: false,
  content: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case PLAY_SPEECH:
      return {
        ...state, 
        speaking: true, 
        content: action.payload
      };

    case STOP_SPEECH:
      return {
        ...state, 
        speaking: false,
        content: ''
      };

    default:
      return state;
  }
};