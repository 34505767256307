import {
    NEW_CARRYOUT_ORDER_ADDED,
    CARRYOUT_ORDER_UPDATED,
    CUSTOMER_CLICKED_ARRIVED,
    CUSTOMER_UPDATE_VEHICLE_DETAILS,
    REFRESH,
    PLAY_AUDIO,
    STOP_AUDIO,
    // PLAY_SPEECH,
    CUSTOMER_ORDER_SENT
} from './types';
import { toast } from 'react-toastify';
import _ from 'lodash';


export const onWebsocketReceived = (data) => {
    return (dispatch, getState) => {
        
        switch(data.msgType){
            case 'newCarryOutOrder':
                // if(!getState().main.carryoutInfoList) return;
                let newCarryoutInfoList = _.keyBy(getState().main.carryoutInfoList, o => o.taskCode);
                // if(!newCarryoutInfoList[data.coTaskInfo.taskCode]) return;
                newCarryoutInfoList[data.coTaskInfo.taskCode] = data.coTaskInfo;
                return dispatch({type: NEW_CARRYOUT_ORDER_ADDED, payload: _.values(newCarryoutInfoList)});
                
            case 'carryOutOrderUpdated':
                // if(!getState().main.carryoutInfoList) return;
                let carryoutInfoList = _.keyBy(getState().main.carryoutInfoList, o => o.taskCode)
                // if(!carryoutInfoList[data.coTaskInfo.taskCode]) return;
                carryoutInfoList[data.coTaskInfo.taskCode] = data.coTaskInfo;
                return dispatch({type: CARRYOUT_ORDER_UPDATED, payload: _.values(carryoutInfoList)})
                
            case 'customerArrivedCarryOutOrder':
                // if(!getState().main.carryoutInfoList) return;
                let arrivedCarryoutInfoList = _.keyBy(getState().main.carryoutInfoList, o => o.taskCode);
                // if(!arrivedCarryoutInfoList[data.coTaskInfo.taskCode]) return;
                arrivedCarryoutInfoList[data.coTaskInfo.taskCode] = data.coTaskInfo;
                toast.info('Customer Arrived')
                dispatch({type: PLAY_AUDIO, payload: 'alert'})
                dispatch({type: STOP_AUDIO, payload: 'alert'})
                dispatch({type: PLAY_AUDIO, payload: 'customerishere'})
                dispatch({type: STOP_AUDIO, payload: 'customerishere'})
                // dispatch({type: PLAY_SPEECH, payload: arrivedCarryoutInfoList[data.coTaskInfo.taskCode].clientInfo.customerName + ' have arrived.'})
                return dispatch({type: CUSTOMER_CLICKED_ARRIVED, payload: _.values(arrivedCarryoutInfoList)})

            case 'customerUpdateDriveUpDetail':
                // if(!getState().main.carryoutInfoList) return;
                let carryoutInfoDriveUpList = _.keyBy(getState().main.carryoutInfoList, o => o.taskCode)
                // if(!carryoutInfoDriveUpList[data.coTaskInfo.taskCode]) return;
                carryoutInfoDriveUpList[data.coTaskInfo.taskCode] = data.coTaskInfo;
                toast.info('Customer Updated Vehicle Details')
                dispatch({type: PLAY_AUDIO, payload: 'alert'})
                dispatch({type: STOP_AUDIO, payload: 'alert'})
                // dispatch({type: PLAY_SPEECH, payload: 'A customer have updated vehicle detail.'})
                return dispatch({type: CUSTOMER_UPDATE_VEHICLE_DETAILS, payload: _.values(carryoutInfoDriveUpList)})

            case 'storeClickSend':
                // if(!getState().main.carryoutInfoList) return;
                let carryoutInfoStoreSendList = _.keyBy(getState().main.carryoutInfoList, o => o.taskCode)
                // if(!carryoutInfoStoreSendList[data.coTaskInfo.taskCode]) return;
                carryoutInfoStoreSendList[data.coTaskInfo.taskCode] = data.coTaskInfo;
                toast.info('Order Send!')
                dispatch({type: PLAY_AUDIO, payload: 'alert'})
                dispatch({type: STOP_AUDIO, payload: 'alert'})
                // dispatch({type: PLAY_SPEECH, payload: 'A Order has been send to customer.'})
                return dispatch({type: CUSTOMER_ORDER_SENT, payload: _.values(carryoutInfoStoreSendList)})

            case "heartBeatReq":
                let carryOutInfoListOnRefresh = getState().main.carryoutInfoList;
                return dispatch({type: REFRESH, payload: carryOutInfoListOnRefresh})

            default:
                return null
        };
    }
};