import Swal from 'sweetalert2/src/sweetalert2.js';

const SUCC_CODE = "000";

const errorCode = {
    ERROR_CARRY_OUT_ORDER_NOT_FOUND: "460",
    ERROR_CARRY_OUT_ORDER_EXIST: "461",
    ERROR_CLIENT_ALREADY_CLICKED: "462",
    ERROR_STORE_DO_NOT_HAVE_CODE: "463",
    ERROR_INVALID_STORE_CODE: "464",
    ERROR_STORE_CODE_MISMATCHED: "465",
};

export const isSuccess = (response) => {
    return response.data.errorCode === SUCC_CODE;
}

export const prompt = (title, content) => {
    Swal.fire(title, content, 'info').then();
}

export const alertError = (response, callBackFunc) => {
    if (response.status === 200) {
        if (response.data.errorCode === SUCC_CODE) {
            return;
        } else if (response.data.errorCode === errorCode.ERROR_CARRY_OUT_ORDER_NOT_FOUND) {
            Swal.fire("Error", "ERROR_CARRY_OUT_ORDER_NOT_FOUND", 'error').then();
        } else if (response.data.errorCode === errorCode.ERROR_CARRY_OUT_ORDER_EXIST) {
            Swal.fire("Error", "ERROR_CARRY_OUT_ORDER_EXIST", 'error').then();
        } else if (response.data.errorCode === errorCode.ERROR_CLIENT_ALREADY_CLICKED) {
            Swal.fire("Error", "Client Already Clicked", 'warning').then();
        } else if (response.data.errorCode === errorCode.ERROR_STORE_DO_NOT_HAVE_CODE) {
            Swal.fire("Error", "ERROR_STORE_DO_NOT_HAVE_CODE", 'error').then();
        } else if (response.data.errorCode === errorCode.ERROR_INVALID_STORE_CODE) {
            Swal.fire("Error", "ERROR_INVALID_STORE_CODE", 'error').then();
        } else if (response.data.errorCode === errorCode.ERROR_STORE_CODE_MISMATCHED) {
            Swal.fire("Error", "ERROR_STORE_CODE_MISMATCHED", 'error').then();
        }
    } else {
        Swal.fire("Fail", "We are having problem to process your request. Please contact support for further assistance.", 'error');
    }
}