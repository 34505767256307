import {
    LIST_CARRYOUT_TASK_SUCCESS,
	LIST_CARRYOUT_TASK_FAIL,
	GET_GROUP_INFO,
	GET_GROUP_INFO_SUCCESS,
	GET_GROUP_INFO_FAIL,
	NEW_CARRYOUT_ORDER_ADDED,
    CARRYOUT_ORDER_UPDATED,
	CUSTOMER_CLICKED_ARRIVED,
	CUSTOMER_UPDATE_VEHICLE_DETAILS,
	REFRESH,
	CUSTOMER_ORDER_SENT,
	NEXT_PAGE,
	RESET_PAGE
} from '../actions/types';

import moment from "moment";

const INITIAL_STATE = {
	error: '',
	loading: false,
	groupInfo: null,
	currentPage: 0,
	totalPage: 0,
	carryoutInfoList: [],
	lastDateTimeWebsocket: null
};

export default (state = INITIAL_STATE, action) => {
	let carryoutInfoList = state.carryoutInfoList;
	let currentPage = state.currentPage;

	switch (action.type) {

		case NEXT_PAGE:
			return{
				...state,
				currentPage: currentPage + 1
			};

		case RESET_PAGE:
			return{
				...state,
				currentPage: 0,
				carryoutInfoList: carryoutInfoList,
			};

		case LIST_CARRYOUT_TASK_SUCCESS:
			return {
				...state,
				loading: false,
				currentPage: currentPage,
				carryoutInfoList: action.payload.taskList,
				totalPage: action.payload.totalPage,
			};

		case LIST_CARRYOUT_TASK_FAIL:
			return {
				...state,
			};

		case GET_GROUP_INFO:
			return {
				...state,
			};

		case GET_GROUP_INFO_SUCCESS:
			return {
				...state,
				loading: false,
				groupInfo: action.payload,
			};

		case GET_GROUP_INFO_FAIL:
			return {
				...state,
			};

		case NEW_CARRYOUT_ORDER_ADDED:
			return {
				...state,
				loading: false,
				lastDateTimeWebsocket: moment(),
				carryoutInfoList: action.payload,
			};

		case CARRYOUT_ORDER_UPDATED:
			return {
				...state,
				lastDateTimeWebsocket: moment(),
				carryoutInfoList: action.payload,
			};

		case CUSTOMER_CLICKED_ARRIVED:
			return {
				...state,
				lastDateTimeWebsocket: moment(),
				carryoutInfoList: action.payload,
			};

		case CUSTOMER_UPDATE_VEHICLE_DETAILS:
			return {
				...state,
				lastDateTimeWebsocket: moment(),
				carryoutInfoList: action.payload,
			};

		case CUSTOMER_ORDER_SENT:
			return {
				...state,
				lastDateTimeWebsocket: moment(),
				carryoutInfoList: action.payload,
			}

		case REFRESH:
			return {
				...state,
				lastDateTimeWebsocket: moment(),
			};

		default:
			return state;
	}
};
