import axios from 'axios';
import moment from 'moment';

import { API_KEY } from '../config';

export const currentTime = moment();

axios.interceptors.request.use(async request => {
    request.params["api_key"] = API_KEY;
    return request;
});
  
axios.interceptors.response.use(response => {
    return response;
});

export const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable){
            return pair[1];
        }
    }
    return(false);
}

export const isIOCompareToTargetTime = (orderInOvenTime, targetOrderTime, inOvenToReadyBufferMin) => {
    const IOTimePlusXmin = orderInOvenTime.clone().add(inOvenToReadyBufferMin, 'm');
    return IOTimePlusXmin.isSameOrAfter(targetOrderTime, 'minute');
};

export const isNowCompareToTargetTime = (targetOrderTime, orderDropToReadyBufferMin) => {
    const nowPlusXmin = currentTime.clone().add(orderDropToReadyBufferMin, 'm');
    return nowPlusXmin.isSameOrAfter(targetOrderTime, 'minute');
};