import React from "react";
import { VERSION } from "../config";

const FooterComponent = () => {
  return (
    <>
      <div className="has-text-right has-text-grey-light is-size-7">
          v <small>{VERSION}</small>
      </div>
    </>
  );
};

export { FooterComponent };
