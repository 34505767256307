import React, { Component } from 'react';
import axios from 'axios';
import { compose } from 'recompose';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { BASE_URL, DEBUG } from './config';

import { Offline } from "react-detect-offline";
import Loading from './components/Loading';
import Home from 'pages/Home';
import NotFoundPage from 'pages/NotFound';

import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.minimal.css';

import 'sweetalert2/src/sweetalert2.scss';

axios.defaults.baseURL = BASE_URL;

class AppBase extends Component {
  componentDidMount() {

  }

  render() {

    const polling = {
      enabled: true,
      url: "https://amboi.dominos.com.my/api/util/pingTest?api_key=23rusdaljk203ad2bl",
      interval: 60000,
      timeout: 60000
    };

    return (
      <>      
        {
          DEBUG ?
            null :
            <Offline polling={polling}>
              <article className="message is-danger">
                <div className="message-header">
                  <p>Error - No Connection Detected, Please Check Your Internet Connection.</p>
                </div>
              </article>
            </Offline>
        }
        <Switch>
          <Route exact path={`${ROUTES.HOME}`} component={Home} />
          <Route path={ROUTES.NOTFOUND} component={NotFoundPage} />
          <Redirect to={ROUTES.NOTFOUND} />
          {/* <Route component={NotFoundPage} /> */}
        </Switch>
        <Loading />

      </>
    );
  }
}

const App = compose(
  withRouter,
)(AppBase);

export default App;