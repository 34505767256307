import {
  PLAY_AUDIO,
  STOP_AUDIO
} from '../actions/types';

const INITIAL_STATE = {
  play: false,
  audioName: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case PLAY_AUDIO:
      return { ...state, play: true, audioName: action.payload };

    case STOP_AUDIO:
      return { ...state, play: false, audioName: action.payload };

    default:
      return state;
  }
};