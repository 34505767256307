export const SHOW_LOADING                            = 'LOADING/SHOW';
export const HIDE_LOADING                            = 'LOADING/HIDE';

export const NEXT_STEP                               = 'NEXT_STEP';
export const PREV_STEP                               = 'PREV_STEP';

export const PLAY_AUDIO                              = 'PLAY_AUDIO';
export const STOP_AUDIO                              = 'STOP_AUDIO';

// -----------------------------------------------------------------------
// ---------ORDER---------------------------------------------------
// -----------------------------------------------------------------------

// export const LIST_CARRYOUT_TASK                       = 'list_carryout_task';
export const LIST_CARRYOUT_TASK_SUCCESS               = 'list_carryout_task_success';
export const LIST_CARRYOUT_TASK_FAIL                  = 'list_carryout_task_fail';

export const GET_GROUP_INFO                           = 'get_group_info';
export const GET_GROUP_INFO_SUCCESS                   = 'get_group_info_success';
export const GET_GROUP_INFO_FAIL                      = 'get_group_info_fail';

export const CLEAR_CARRYOUT_TASK                      = 'clear_carryout_task';
export const CLEAR_CARRYOUT_TASK_SUCCESS              = 'clear_carryout_task_success';
export const CLEAR_CARRYOUT_TASK_FAIL                 = 'clear_carryout_task_fail';

export const SEND_CARRYOUT_TASK                      = 'send_carryout_task';
export const SEND_CARRYOUT_TASK_SUCCESS              = 'send_carryout_task_success';
export const SEND_CARRYOUT_TASK_FAIL                 = 'send_carryout_task_fail';

// -----------------------------------------------------------------------
// ---------TEXT 2 SPEECH ------------------------------------------------
// -----------------------------------------------------------------------

export const PLAY_SPEECH                              ='play_speech';
export const STOP_SPEECH                              ='stop_speech';

// -----------------------------------------------------------------------
// ---------WEBSOCKET---------------------------------------------------
// -----------------------------------------------------------------------
export const START_WEBSOCKET                          = 'start_websocket';
export const STOP_WEBSOCKET                           = 'stop_websocket';

export const NEW_CARRYOUT_ORDER_ADDED                 = 'new_carryout_order_added';
export const CARRYOUT_ORDER_UPDATED                   = 'carryout_order_updated';
export const CUSTOMER_CLICKED_ARRIVED                 = 'customer_clicked_arrived';
export const CUSTOMER_UPDATE_VEHICLE_DETAILS          = 'customer_update_vehicle_details';
export const CUSTOMER_ORDER_SENT                      = 'customer_order_sent';

export const NEXT_PAGE                                  = 'NEXT_PAGE';
export const RESET_PAGE                                 = 'RESET_PAGE';

export const REFRESH                                  = 'REFRESH';
