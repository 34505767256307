import { combineReducers } from 'redux';

import MainReducer from './MainReducer';
import LoadingReducer from './LoadingReducer';
import WebsocketReducer from './WebsocketReducer';
import AudioReducer from './AudioReducer';
import Text2SpeechReducer from './Text2SpeechReducer';

const appReducer = combineReducers({
    main: MainReducer,
    loading: LoadingReducer,
    websocket: WebsocketReducer,
    audio: AudioReducer,
    speech: Text2SpeechReducer,

});

const rootReducer = (state, action) => {
//   if (action.type === BACKEND_LOGOUT_USER) {
//     state = undefined;
//   }

  return appReducer(state, action);
};

export default rootReducer;