import React, { Component } from 'react';
import { connect } from 'react-redux';
import sweetsoundeffect from '../assets/audio/sweetsoundeffect.wav';
import customerishere from '../assets/audio/customerishere.wav';

import * as UtliService from "../services/utli-service";

import {
    playAudio,
    stopAudio
} from '../actions'

const audioSweetsoundeffect = new Audio(sweetsoundeffect);
const audioCustomerishere = new Audio(customerishere);


class AudioAlert extends Component {
   
    componentDidMount() {
        this.onLoad();
        audioSweetsoundeffect.addEventListener('ended', () => this.props.stopAudio());
        audioCustomerishere.addEventListener('ended', () => this.props.stopAudio());
    }

    componentWillUnmount() {
        audioSweetsoundeffect.removeEventListener('ended', () => this.props.stopAudio());
        audioCustomerishere.removeEventListener('ended', () => this.props.stopAudio());
    }

    onLoad() {
        UtliService.prompt('Note', 'An alert will be sounded when a customer is arrived.');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.play !== this.props.play) {
            if(this.props.audioName === 'alert') audioSweetsoundeffect.play();
            if(this.props.audioName === 'customerishere') audioCustomerishere.play();
        } else {
            if(this.props.audioName === 'alert') audioSweetsoundeffect.pause();
            if(this.props.audioName === 'customerishere') audioCustomerishere.pause();
        }
      }

    // togglePlay = () => {
    //     this.setState({ play: !this.state.play }, () => {
    //         this.state.play ? audio.play() : audio.pause();
    //     });
    // }

    render() {
        return (
            <div>
                {/* <button onClick={this.togglePlay}>{this.state.play ? 'Pause' : 'Play'}</button> */}
            </div>
        );
    }
}

const mapStateToProps = ({audio}) => {
    const {play, audioName} = audio;
    return {
        play,
        audioName
    };
};

export default connect(mapStateToProps, { stopAudio, playAudio})(AudioAlert);