import {
    PLAY_SPEECH,
    STOP_SPEECH,
} from './types';

export const playSpeech = (speech) => {
    return async (dispatch) => {
        dispatch({
            type: PLAY_SPEECH,
            payload: speech
        });
    };
}

export const stopSpeech = () => {
    return async (dispatch) => {
        dispatch({
            type: STOP_SPEECH,
        });
    };
}