import React, { Component } from "react";

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import * as ROUTES from '../constants/routes';

// import { getQueryVariable } from "services";

import { WebsocketServices } from '../services/websocket-service';
import Content from "./Content";
import AudioAlert from "components/AudioAlert";
import { ToastContainer } from 'react-toastify';
import Text2Speech from "components/Text2Speech";
// import { DEBUG } from "config";

class HomePageBase extends Component {
  // constructor(props) {
  //   super(props);

  //   this.toggle = this.toggle.bind(this);
  //   this.state = {
  //     activeTab: '1'
  //   };
  // }

  componentDidMount() {
    // console.log(getQueryVariable('storeId'));
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      alert('Notice: Please use Google Chrome as your web browser for this Over-The-Counter Website.');
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      // alert('Chrome');
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      alert('Notice: Please use Google Chrome as your web browser for this Over-The-Counter Website.');
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      alert('Notice: Please use Google Chrome as your web browser for this Over-The-Counter Website.');
    } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)){ //IF IE > 10
      alert('Notice: Please use Google Chrome as your web browser for this Over-The-Counter Website.');
    } else {
      alert('Notice: Please use Google Chrome as your web browser for this Over-The-Counter Website.');
    }
  }

  render() {
    return (
      <>
        <WebsocketServices />
        <AudioAlert />
        <Text2Speech />
        <ToastContainer
          pauseOnFocusLoss={false}
          position='top-right'
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
        />
        <Content />
      </>

    );
  }
}

const mapStateToProps = (props) => {
  return props;
};

const Home = compose(
  withRouter,
  connect(mapStateToProps),
)(HomePageBase);

export default Home;
